.github-ball {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #000000;
    border-radius: 50%;
    padding: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .github-ball:hover {
    background-color: #6c00c4;
  }
  