body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f7fb;
    color: #333;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    margin: 0;
  }
  
  .container {
    width: 800px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  h1:first-child {
      
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: bold;
    background: linear-gradient(90deg, #13b9be, #6200ff);
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
  }

  h1:last-child {
      
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: bold;
    background: linear-gradient(90deg, #13b9be, #6200ff);
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
  }
  
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
    background: linear-gradient(90deg, #ec6002, #ff4500);
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
  }

  .error-title {
    font-size: 1.5rem;
    font-weight: bold;
    background: linear-gradient(90deg, #d43301, #ff4500);
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
  }

  .error-message {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    text-align: center;
    margin-bottom: 15px;
  }

  .codeInput {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-control {
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 1rem;
    transition: border 0.3s ease;
    width: 100%;
    margin: 10px;
  }
  
  .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  .response {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #333;
    text-align: left;
    margin: 10px 10px 20px 10px;
    font-family: 'Helvetica', 'Arial', sans-serif;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 10px 15px;
  }
  
  textarea {
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
    max-width: 100%;
    min-width: 50%;
  }
  
  button {
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #007bff;
    border: none;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:disabled {
    background-color: #4ea0cf;
    cursor: not-allowed;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:focus {
    outline: none;
  }
  
  .codeResult {
    margin-top: 20px;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 10px;
    font-size: 1rem;
    color: #333;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .refactoredCode {
    background-color: #1e1e1e;
    color: #d4d4d4;
    padding: 15px;
    border-radius: 8px;
    font-family: 'Fira Code', 'Courier New', monospace;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: left;
    margin: 15px;
    overflow-x: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-size: 0.95rem;
    line-height: 1.5; 
}

  .closeButton {
    background-color: transparent;
    color: #888;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    right: 10px;
    top: 10px;
  }
  
  .closeIcon {
    font-size: 1.5rem;
    color: #888;
  }
  
  .closeButton:hover .closeIcon {
    color: #000;
    background-color: transparent;
    border-radius: 5px;
  }
  
  .closeButton:hover {
    border-color: transparent;
  }

  .error-message {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    text-align: center;
    background-color: #f8d7da;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #ff4500;
    margin: 15px;
    margin-top: 10px;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .error-container {
    margin-top: 20px;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 10px;
    font-size: 1rem;
    color: #333;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 20px;
      width: 80%;
      margin: 0 auto;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    h4 {
      font-size: 1.25rem;
    }
  }
  